<template>
  <div class="d-flex flex-column align-items-center">


    <div v-if="cancel_in_seconds !== null && cancel_in_seconds > 0">
      <p>
        {{$t('SHOP_ORDER.CANCEL_IN_SECONDS', { seconds: cancel_in_seconds })}}
      </p>
    </div>


    <div class="mt-32" v-if="has_errors">
      <b-alert :show="error === 'invalid_link'" variant="danger">
        {{$t('SHOP_ORDER.INVALID_LINK')}}
      </b-alert>
      <b-alert :show="error === 'get_orders'" variant="danger"
        >{{$t('SHOP_ORDER.ERROR_GET')}}</b-alert
      >

      <b-alert :show="error === 'no_orders'" variant="danger"
        >{{$t('SHOP_ORDER.NO_ORDERS')}}</b-alert
      >
      <b-alert :show="error === 'timeout'" variant="danger">
        {{$t('SHOP_ORDER.TIMEOUT')}}
      </b-alert>
    </div>

    

    <div v-if="!has_errors && order && !paymentSuccess && !timeout">


      <b-row align-h="center">
        <b-col xl="6" lg="8" md="12" sm="12" :style="min_width">
          <div class="mt-6 shadow-lg">
            <div
              class="d-flex flex-column align-items-center py-12 bg-linear-gradient-primary text-white"
            >
              <h3 class="text-center mt-0 mb-0">{{ }}</h3>
            </div>
            <b-card-body class="py-16">

              <PendingPaymentInfo 
                :member="{}" 
                :order="order" 
                :products="order ? order.shop_order_items : []" 
              />

              <!--<div class="d-flex justify-content-center mb-3">
                <memlist-spinner />
              </div>-->

              <div v-if="!paymentSuccess && !timeout" class="mt-12 w-100" id="checkout-container"></div>

            </b-card-body>
          </div>
        </b-col>
      </b-row>


    </div>

    <div v-if="paymentSuccess">
      <PaymentComplete :order="order" :member="{}" :products="order ? order.shop_order_items : []" />
    </div>

  </div>
</template>

<style lang="scss" scoped></style>
<script>
import {
    embed,
    SessionLoaded,
    SessionUpdated,
    SessionPayment,
    SessionPaymentError,
    SessionCancel,
    SessionNotFound,
} from "@dintero/checkout-web-sdk";

import dayjs from 'dayjs';
import axios from 'axios';
import PaymentComplete from '@/view/components/PaymentComplete.vue';
import PendingPaymentInfo from '@/view/components/PendingPaymentInfo.vue';
import messageParent from '@/core/services/messageParent';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { is_mobile } from '@/core/services/utils';

export default {
  name: 'dintero-vue-wrapper',
  props: [],
  mixins: [ toasts ],
  components: {
    PaymentComplete,
    PendingPaymentInfo
  },
  computed: {
    cancellation_in_seconds() {
      return dayjs().diff(this.cancel_at_time, 'seconds');
    },
    has_errors() {
      return this.error !== '';
    },
    min_width() {
      return {
        'min-width': is_mobile()  ? '460px' : '640px'
      };
    },
    
  },
  data() {
    return {
      has_errors: false,
      timeout: false,
      error: '',
      proceed: false,
      shop_order_id: null,
      order: null,
      paymentSuccess: false,
      interval_id: null,
      token: null,
      renew_id: null,
      cancel_in_seconds: null
    };
  },
  mounted() {

    this.shop_order_id = this.$route.params.shop_order_id;
    this.dintero_id = this.$route.params.dintero_id;
    this.renew_id = this.$route.params.renew_id;
    this.token = this.$route.params.token;

    if (!this.shop_order_id || !this.dintero_id) {
      this.error = 'invalid_link';
      this.has_errors = true;

      return;
    }

    this.get_orders_for_member();
  },
  watch: {},
  methods: {
    try_start_dintero() {
      if (this.interval_id === null) {
        this.interval_id = setInterval(this.try_start_dintero, 1000);

        return;
      }

      this.$nextTick(()=>{
        this.startDintero();
      });
    },

    stop_try_dintero() {
      clearInterval(this.interval_id);
      this.interval_id = null;
    },


    async startDintero() {

      const that = this;

      setTimeout(function(){
        if (that.paymentSuccess) {
          return;
        }

        that.error = 'timeout';
        that.timeout = true;
        that.cancelOrder();
      }, 5 * 60 * 1000);

      const container = document.getElementById('checkout-container');

      if (container === null) {
        console.error('container is null');
        return;
      }

      this.stop_try_dintero();

      const checkout = await embed({
        container,
        sid: this.dintero_id,
        language: 'sv', // optional parameter, an ISO 639-1 two-letter language code
        onSession: (event /*: SessionLoaded | SessionUpdated*/) => {
            console.log("session", event.session);
            that.stop_try_dintero();
        },
        onPayment: (event/*: SessionPaymentAuthorized | SessionPaymentOnHold*/, checkout) => {
            console.log("transaction_id", event.transaction_id);
            console.log("href", event.href);
          
            try {
              
              let url = this.order.is_renew
                ? this.order.company.company_settings.post_renew_url
                : this.order.company.company_settings.post_register_url;


              if (this.order.type === 'INSURANCE') {
                this.url = `${window.location.origin}/profile`;
              }
              else {
                // add http:// if not present

                if (url && url.length > 4) {
                  if (!url.match(/^https?:\/\//)) {
                    url = 'http://' + url;
                  }

                  messageParent(JSON.stringify({ redirect: url }));

                  window.location = url;
                }
                else {
                  this.paymentSuccess = true;
                  checkout.destroy();
                }
              }
              

            } catch (err) {
              console.error('checkout destroy error: ', err);
            }
        },
        onPaymentError: (event/*: SessionPaymentError*/, checkout) => {
            console.log("href", event.href);
            checkout.destroy();
        },
        onSessionCancel: (event/*: SessionCancel*/, checkout) => {
            console.log("href", event.href);
            checkout.destroy();

            try {
              this.cancelOrder();
              // checkout.destroy();
            } catch (err) {
              console.error('checkout destroy error: ', err);
            }
        },
        onSessionNotFound: (event/*: SessionNotFound*/, checkout) => {
            console.log("session not found (expired)", event.type);
            checkout.destroy();
        },
        onSessionLocked: (event/*: SessionLocked*/, checkout, callback) => {
            console.log("pay_lock_id", event.pay_lock_id);
            callback(); // refresh session
        },
        onSessionLockFailed: (event/*: SessionLockFailed*/, checkout) => {
            console.log("session lock failed");
        },
        onActivePaymentType: function(event/*: ActivePaymentProductType*/, checkout) {
            console.log("payment product type selected", event.payment_product_type);
        },
        onValidateSession: function(event/*: ValidateSession*/, checkout, callback) {
            console.log("validating session", event.session);
            callback({
                success: true,
                clientValidationError: undefined,
            });
        },
      });

    },
    cancelOrder() {
      axios
        .put(`/shop_order/cancel/${this.order.shop_order_id}`, { token: this.order.token })
        .then(res => {
          this.error = 'timeout';
          this.timeout = true;
          this.has_errors = true;
        })
        .catch(err => {
          console.error(err);
        });
    },

    check_cancellation() {

      if (this.cancel_in_seconds === null || this.cancel_in_seconds === undefined) {
        return;
      }

      const int_id = setInterval(()=>{
        
        this.cancel_in_seconds = this.cancel_in_seconds - 1;

        if (this.cancel_in_seconds <= 0) {
          this.timeout = true;
          this.error = 'timeout';
          this.has_errors = true;

          clearInterval(int_id);
        }
      }, 1000);
    },

    async get_orders_for_member() {
      try {
        const res = await axios.get(`/shop_order/pending/${this.dintero_id}`);

        if (res.status === 200) {
          if (res.data.is_cancelled) {
            this.error = 'timeout';
            this.timeout = true;
            this.has_errors = true;
            return;
          }

          this.cancel_in_seconds = res.data.cancel_in_seconds;

          if (this.cancel_in_seconds !== null && this.cancel_in_seconds !== undefined) {
            this.check_cancellation();
          }
          
          if (res.data === null || res.data.length === 0) {
            this.error = 'no_orders';
            this.has_errors = true;
          } else {
            this.order = res.data;

            this.proceed = true;

            this.$nextTick(()=>{
              this.try_start_dintero();
            });

          }
        } else {
          this.error = 'get_orders';
          this.has_errors = true;
        }

      }
      catch (err) {
        console.error(err);
        this.error = 'get_orders';
        this.has_errors = true;
      }
    }
  }
};
</script>
